import React from 'react';

export const defaultSSR = {
  isValidCategorySSR: null,
  isDesktopSSR: false,
  SSRPath: null,
};

export const setSSRContextValues = (props) => {
  return { ...defaultSSR, ...props };
};

export const SSRContext = React.createContext(defaultSSR);
